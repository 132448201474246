import logo from './Assests/Images/Ruumart Group Logo white.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Thank you for visiting Ruumart.com<br></br>The website will be released soon
        </p>
      </header>
    </div>
  );
}

export default App;
